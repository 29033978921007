<!--
 * @Author: wang peng
 * @Description: 直联电站新增
-->
<template>
  <ykc-form class="el-form-wrap pileInspection-add">
    <!-- 基础信息 -->
    <ykc-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm0"
      class="el-form-wrap"
      v-show="active === 0">
      <ykc-form-item label="巡检人" prop="inspectPerson">
        <ykc-input placeholder="请输入巡检人" v-model="ruleForm.inspectPerson"></ykc-input>
      </ykc-form-item>

      <ykc-form-item label="巡检时间" prop="inspectDate">
        <ykc-date-picker v-model="ruleForm.inspectDate" type="date"></ykc-date-picker>
      </ykc-form-item>

      <ykc-form-item label="电站名称" prop="stationName">
        <ykc-input placeholder="请输入电站名称" v-model="ruleForm.stationName"></ykc-input>
      </ykc-form-item>

      <ykc-form-item label="电站地址" prop="stationAddress">
        <ykc-input placeholder="请输入电站地址" v-model="ruleForm.stationAddress"></ykc-input>
      </ykc-form-item>

      <ykc-form-item label="设备数量" prop="equipmentNum">
        <ykc-input placeholder="请输入设备数量" v-model="ruleForm.equipmentNum">
          <template slot="append">台</template>
        </ykc-input>
      </ykc-form-item>

      <ykc-form-item label="桩类型" prop="pileType">
        <ykc-dropdown
          :data="[
            { id: 1, name: '直流' },
            { id: 2, name: '交流' },
          ]"
          v-model="ruleForm.pileType"></ykc-dropdown>
      </ykc-form-item>

      <template v-if="ruleForm.pileType === 1">
        <ykc-form-item label="模块配置数量" prop="moduleConfigNum">
          <ykc-input
            placeholder="请输入模块配置数量"
            v-model="ruleForm.moduleConfigNum"></ykc-input>
        </ykc-form-item>

        <ykc-form-item label="模块工作数量" prop="moduleWorkNum">
          <ykc-input placeholder="请输入模块工作数量" v-model="ruleForm.moduleWorkNum"></ykc-input>
        </ykc-form-item>

        <ykc-form-item label="总电度值" prop="totalElectricalValue">
          <ykc-input
            placeholder="请输入总电度值"
            v-model="ruleForm.totalElectricalValue"></ykc-input>
        </ykc-form-item>

        <ykc-form-item class="flex-box" label="进线电源相间电压">
          <ykc-form-item prop="incomePowerSupplyVoltage">
            <ykc-input placeholder="请输入" v-model="ruleForm.incomePowerSupplyVoltage">
              <template slot="prepend">AB</template>
            </ykc-input>
          </ykc-form-item>

          <ykc-form-item prop="incomePowerSupplyVoltage1">
            <ykc-input
              class="both-margin"
              placeholder="请输入"
              v-model="ruleForm.incomePowerSupplyVoltage1">
              <template slot="prepend">BC</template>
            </ykc-input>
          </ykc-form-item>

          <ykc-form-item prop="incomePowerSupplyVoltage2">
            <ykc-input placeholder="请输入" v-model="ruleForm.incomePowerSupplyVoltage2">
              <template slot="prepend">AC</template>
            </ykc-input>
          </ykc-form-item>
        </ykc-form-item>

        <ykc-form-item class="flex-box" label="交流电压/频率">
          <ykc-form-item prop="exchangeVoltage">
            <ykc-input placeholder="请输入" v-model="ruleForm.exchangeVoltage">
              <template slot="prepend">A相</template>
            </ykc-input>
          </ykc-form-item>

          <ykc-form-item prop="exchangeVoltage1">
            <ykc-input class="both-margin" placeholder="请输入" v-model="ruleForm.exchangeVoltage1">
              <template slot="prepend">B相</template>
            </ykc-input>
          </ykc-form-item>

          <ykc-form-item prop="exchangeVoltage2">
            <ykc-input placeholder="请输入" v-model="ruleForm.exchangeVoltage2">
              <template slot="prepend">C相</template>
            </ykc-input>
          </ykc-form-item>
        </ykc-form-item>

        <ykc-form-item class="flex-box" label="软件版本">
          <ykc-form-item prop="softwareVersion">
            <ykc-input placeholder="请输入" v-model="ruleForm.softwareVersion">
              <template slot="prepend">模块</template>
            </ykc-input>
          </ykc-form-item>

          <ykc-form-item prop="softwareVersion1">
            <ykc-input class="both-margin" placeholder="请输入" v-model="ruleForm.softwareVersion1">
              <template slot="prepend">监控</template>
            </ykc-input>
          </ykc-form-item>

          <ykc-form-item prop="softwareVersion2">
            <ykc-input placeholder="请输入" v-model="ruleForm.softwareVersion2">
              <template slot="prepend">控制板</template>
            </ykc-input>
          </ykc-form-item>
        </ykc-form-item>

        <ykc-form-item class="flex-box" label="车型">
          <ykc-form-item prop="carModel">
            <ykc-input placeholder="请输入" v-model="ruleForm.carModel">
              <template slot="prepend">厂家</template>
            </ykc-input>
          </ykc-form-item>

          <ykc-form-item prop="carModel1" style="margin-left: 150px">
            <ykc-input placeholder="请输入" v-model="ruleForm.carModel1">
              <template slot="prepend">型号</template>
            </ykc-input>
          </ykc-form-item>
        </ykc-form-item>

        <ykc-form-item class="flex-box" label="参数设置检查">
          <ykc-form-item prop="parameterSetCheck">
            <ykc-input placeholder="请输入" v-model="ruleForm.parameterSetCheck">
              <template slot="prepend">监控限电压</template>
            </ykc-input>
          </ykc-form-item>

          <ykc-form-item prop="parameterSetCheck1" style="margin-left: 100px">
            <ykc-input placeholder="请输入" v-model="ruleForm.parameterSetCheck1">
              <template slot="prepend">监控限电流</template>
            </ykc-input>
          </ykc-form-item>
        </ykc-form-item>

        <ykc-form-item prop="parameterSetCheck2">
          <ykc-input placeholder="请输入" v-model="ruleForm.parameterSetCheck2">
            <template slot="prepend">读卡器波特率/类型</template>
          </ykc-input>
        </ykc-form-item>

        <ykc-form-item label="电子档案" prop="electronicFiles">
          <ykc-input placeholder="请输入" v-model="ruleForm.electronicFiles">
            <template slot="prepend">正常充电BMS报文</template>
          </ykc-input>
        </ykc-form-item>
      </template>

      <ykc-form-item
        prop="img"
        label="附件"
        class="file-error"
        bottomTip="温馨提示：只能上传jpg/jpeg/png文件，且不超过3M">
        <div class="flex upload-img-box">
          <ykc-upload
            v-for="(item, index) in uploadData"
            :key="index"
            :text="item.name"
            :size="3"
            :ref="`stationFile${item.id}`"
            @handleChange="uploadImg"
            @handleRemove="handleRemoveLogo"></ykc-upload>
        </div>
      </ykc-form-item>
    </ykc-form>

    <!-- 1 -->
    <ykc-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm1"
      class="el-form-wrap"
      v-show="active === 1">
      <ykc-form-item
        :required="true"
        v-if="ruleForm.pileType === 1"
        label="门锁是否关闭"
        prop="detail2.doorLockFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail2.doorLockFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail2.doorLockFlag === 2"
          v-model="ruleForm.detail2.doorLockProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        v-if="ruleForm.pileType === 1"
        label="线内接线是否牢固"
        prop="detail2.internalWiringFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail2.internalWiringFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail2.internalWiringFlag === 2"
          v-model="ruleForm.detail2.internalWiringProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        v-if="ruleForm.pileType === 1"
        label="标志标识是否清晰"
        prop="detail2.signIdentificationFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail2.signIdentificationFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail2.signIdentificationFlag === 2"
          v-model="ruleForm.detail2.signIdentificationProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        label="充电异常停止原因"
        prop="detail2.chargeExceptionStopFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail2.chargeExceptionStopFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail2.chargeExceptionStopFlag === 2"
          v-model="ruleForm.detail2.chargeExceptionStopProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item :required="true" label="故障记录是否异常" prop="detail2.errorRecordFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail2.errorRecordFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail2.errorRecordFlag === 2"
          v-model="ruleForm.detail2.errorRecordProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item :required="true" label="机柜风扇运转是否正常" prop="detail1.cabinetFanFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail1.cabinetFanFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail1.cabinetFanFlag === 2"
          v-model="ruleForm.detail1.cabinetFanProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        v-if="ruleForm.pileType === 1"
        label="外壳是否无变形腐蚀"
        prop="detail2.housingStateFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail2.housingStateFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail2.housingStateFlag === 2"
          v-model="ruleForm.detail2.housingStateProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        v-if="ruleForm.pileType === 1"
        label="设备运行有无异常异味"
        prop="detail2.equipmentOperationFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail2.equipmentOperationFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail2.equipmentOperationFlag === 2"
          v-model="ruleForm.detail2.equipmentOperationProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        v-if="ruleForm.pileType === 1"
        label="检查扫码充电功能是否正常"
        prop="detail2.checkScanCodeFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail2.checkScanCodeFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail2.checkScanCodeFlag === 2"
          v-model="ruleForm.detail2.checkScanCodeProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        v-if="ruleForm.pileType === 1"
        label="电缆进线口是否用防火泥密封"
        prop="detail1.fireproofMudFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail1.fireproofMudFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail1.fireproofMudFlag === 2"
          v-model="ruleForm.detail1.fireproofMudProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        v-if="ruleForm.pileType === 1"
        label="进出电缆穿越箱体处是否封堵"
        prop="detail2.cableCrossBoxFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail2.cableCrossBoxFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail2.cableCrossBoxFlag === 2"
          v-model="ruleForm.detail2.cableCrossBoxProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        label="显示屏操作页面是否有报警故障"
        prop="detail1.displayScreenPageFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail1.displayScreenPageFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail1.displayScreenPageFlag === 2"
          v-model="ruleForm.detail1.displayScreenPageProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        v-if="ruleForm.pileType === 1"
        label="防水，防尘，防腐蚀性是否完好"
        prop="detail2.threeProofingFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail2.threeProofingFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail2.threeProofingFlag === 2"
          v-model="ruleForm.detail2.threeProofingProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        v-if="ruleForm.pileType === 1"
        label="检查充电枪接口是否能够正常使用"
        prop="detail2.checkGunInterfaceFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail2.checkGunInterfaceFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail2.checkGunInterfaceFlag === 2"
          v-model="ruleForm.detail2.checkGunInterfaceProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        label="防尘网是否积聚灰尘，是否及时清理"
        prop="detail1.dustProofNetFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail1.dustProofNetFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail1.dustProofNetFlag === 2"
          v-model="ruleForm.detail1.dustProofNetProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        v-if="ruleForm.pileType === 1"
        label="进线电缆是否适合充电桩的电压和电流"
        prop="detail1.incomeCableFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail1.incomeCableFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail1.incomeCableFlag === 2"
          v-model="ruleForm.detail1.incomeCableProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        v-if="ruleForm.pileType === 1"
        label="防雷系统是否正常，接地线连接有无松动"
        prop="detail1.protectLightningSystemFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail1.protectLightningSystemFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail1.protectLightningSystemFlag === 2"
          v-model="ruleForm.detail1.protectLightningSystemProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        label="充电位消防设置是否齐全,桩位环境是否干净"
        prop="detail1.chargeFireProtectionFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail1.chargeFireProtectionFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail1.chargeFireProtectionFlag === 2"
          v-model="ruleForm.detail1.chargeFireProtectionProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        label="桩体内模块是否正常，有无报警等异常情况"
        prop="detail1.pileInnerModuleFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail1.pileInnerModuleFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail1.pileInnerModuleFlag === 2"
          v-model="ruleForm.detail1.pileInnerModuleProblem"></ykc-input>
      </ykc-form-item>
    </ykc-form>

    <!-- 2 -->
    <ykc-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm1"
      class="el-form-wrap"
      v-show="active === 2">
      <ykc-form-item
        :required="true"
        label="模块风扇积尘是否严重，散热及风道是否正常"
        prop="detail1.moduleFanFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail1.moduleFanFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail1.moduleFanFlag === 2"
          v-model="ruleForm.detail1.moduleFanProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        v-if="ruleForm.pileType === 1"
        label="充电桩显示屏是否正常亮起，指示灯是否亮起"
        prop="detail2.pileDisplayScreenFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail2.pileDisplayScreenFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail2.pileDisplayScreenFlag === 2"
          v-model="ruleForm.detail2.pileDisplayScreenProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        v-if="ruleForm.pileType === 1"
        label="箱体底座是否有损坏，和裂痕，倾斜，晃动现象"
        prop="detail2.boxBaseFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail2.boxBaseFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail2.boxBaseFlag === 2"
          v-model="ruleForm.detail2.boxBaseProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        label="充电桩表面标示是否明显，充电桩上有无异物等"
        prop="detail1.pileSurfaceMarkingFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail1.pileSurfaceMarkingFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail1.pileSurfaceMarkingFlag === 2"
          v-model="ruleForm.detail1.pileSurfaceMarkingProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        label="充电桩底座是否有损坏，裂痕，倾斜，晃动现象"
        prop="detail1.pileBaseFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail1.pileBaseFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail1.pileBaseFlag === 2"
          v-model="ruleForm.detail1.pileBaseProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        label="急停按钮功能是否正常，桩外故障灯是否正常亮起"
        prop="detail1.emergencyStopButtonFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail1.emergencyStopButtonFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail1.emergencyStopButtonFlag === 2"
          v-model="ruleForm.detail1.emergencyStopButtonProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        label="检查充电桩控制电路板，内部各个元器件有无老化"
        prop="detail1.checkCircuitBoardFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail1.checkCircuitBoardFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail1.checkCircuitBoardFlag === 2"
          v-model="ruleForm.detail1.checkCircuitBoardProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        v-if="ruleForm.pileType === 1"
        label="在正常充电情况下，显示屏充电参数数值是否正常"
        prop="detail2.screenChargeValueFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail2.screenChargeValueFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail2.screenChargeValueFlag === 2"
          v-model="ruleForm.detail2.screenChargeValueProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        v-if="ruleForm.pileType === 1"
        label="使用用户卡对每一台充电桩进行检查是否正常充电"
        prop="detail2.useUserCardFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail2.useUserCardFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail2.useUserCardFlag === 2"
          v-model="ruleForm.detail2.useUserCardProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        label="检查充电桩供电端电压，对地电压，是否正常值范围"
        prop="detail1.checkPileVoltageFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail1.checkPileVoltageFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail1.checkPileVoltageFlag === 2"
          v-model="ruleForm.detail1.checkPileVoltageProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        label="主板与计费等电路板连接线有无松动，板面是否积尘"
        prop="detail1.circuitBoardConnectFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail1.circuitBoardConnectFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail1.circuitBoardConnectFlag === 2"
          v-model="ruleForm.detail1.circuitBoardConnectProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        v-if="ruleForm.pileType === 1"
        label="箱体内部器件电缆等是否整齐，松动老化等问题，标识是否清楚"
        prop="detail1.boxInnerComponentFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail1.boxInnerComponentFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail1.boxInnerComponentFlag === 2"
          v-model="ruleForm.detail1.boxInnerComponentProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        label="充电枪及线缆是否正常，且是否存在损坏或残留水灰尘和杂物情况"
        prop="detail1.chargeGunFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail1.chargeGunFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail1.chargeGunFlag === 2"
          v-model="ruleForm.detail1.chargeGunProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        label="配电柜门锁是否损坏，指示灯是否正常，配电柜门是否正常"
        prop="detail1.distributionBoxLockFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail1.distributionBoxLockFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail1.distributionBoxLockFlag === 2"
          v-model="ruleForm.detail1.distributionBoxLockProblem"></ykc-input>
      </ykc-form-item>

      <ykc-form-item
        :required="true"
        v-if="ruleForm.pileType === 1"
        label="配电柜内部断路器等器件是否有烧毁，烧黑的现象，节点端子是否良好"
        prop="detail1.distributionBoxComponentFlag">
        <ykc-radio
          :data="[
            { id: 1, name: '正常' },
            { id: 2, name: '有问题' },
          ]"
          v-model="ruleForm.detail1.distributionBoxComponentFlag"></ykc-radio>
        <ykc-input
          class="m-top"
          maxlength="500"
          type="textarea"
          placeholder="请输入问题处理详细信息"
          v-if="ruleForm.detail1.distributionBoxComponentFlag === 2"
          v-model="ruleForm.detail1.distributionBoxComponentProblem"></ykc-input>
      </ykc-form-item>
    </ykc-form>
  </ykc-form>
</template>
<script>
  import regexpObj from '@/utils/regexp';
  import { omManagementApi, uploadUtils } from '@/service/apis';

  export default {
    name: 'AddOrEdit',
    components: {},
    props: {
      id: {
        type: Number,
        default: 0,
      },
      active: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        activeId: '',
        addressName: '', // 省市区名称
        selectedStatus: 'active',
        detail: {}, // 详情数据
        addressData: [], // 城市数据源
        OperatorListData: [], // 归属商户数据源
        uploadData: [
          { id: 1, name: '选择图片' },
          { id: 2, name: '选择图片' },
          { id: 3, name: '选择图片' },
        ],
        ruleForm: {
          inspectPerson: '',
          inspectDate: '',
          stationId: 0,
          stationName: '',
          stationAddress: '',
          equipmentNum: null,
          pileType: 1,
          moduleConfigNum: null,
          moduleWorkNum: null,
          totalElectricalValue: null,
          incomePowerSupplyVoltage: '',
          incomePowerSupplyVoltage1: '',
          incomePowerSupplyVoltage2: '',
          exchangeVoltage: '',
          exchangeVoltage1: '',
          exchangeVoltage2: '',
          softwareVersion: '',
          softwareVersion1: '',
          softwareVersion2: '',
          carModel: '',
          carModel1: '',
          parameterSetCheck: '',
          parameterSetCheck1: '',
          parameterSetCheck2: '',
          electronicFiles: '',
          filePath: [],
          detail1: {
            chargeFireProtectionFlag: 1,
            chargeFireProtectionProblem: '',
            pileSurfaceMarkingFlag: 1,
            pileSurfaceMarkingProblem: '',
            pileBaseFlag: 1,
            pileBaseProblem: '',
            chargeGunFlag: 1,
            chargeGunProblem: '',
            protectLightningSystemFlag: 1,
            protectLightningSystemProblem: '',
            distributionBoxLockFlag: 1,
            distributionBoxLockProblem: '',
            distributionBoxComponentFlag: 1,
            distributionBoxComponentProblem: '',
            boxInnerComponentFlag: 1,
            boxInnerComponentProblem: '',
            incomeCableFlag: 1,
            incomeCableProblem: '',
            dustProofNetFlag: 1,
            dustProofNetProblem: '',
            fireproofMudFlag: 1,
            fireproofMudProblem: '',
            pileInnerModuleFlag: 1,
            pileInnerModuleProblem: '',
            circuitBoardConnectFlag: 1,
            circuitBoardConnectProblem: '',
            moduleFanFlag: 1,
            moduleFanProblem: '',
            checkPileVoltageFlag: 1,
            checkPileVoltageProblem: '',
            checkCircuitBoardFlag: 1,
            checkCircuitBoardProblem: '',
            emergencyStopButtonFlag: 1,
            emergencyStopButtonProblem: '',
            displayScreenPageFlag: 1,
            displayScreenPageProblem: '',
            cabinetFanFlag: 1,
            cabinetFanProblem: '',
          },
          detail2: {
            useUserCardFlag: 1,
            useUserCardProblem: '',
            pileDisplayScreenFlag: 1,
            pileDisplayScreenProblem: '',
            checkScanCodeFlag: 1,
            checkScanCodeProblem: '',
            checkGunInterfaceFlag: 1,
            checkGunInterfaceProblem: '',
            screenChargeValueFlag: 1,
            screenChargeValueProblem: '',
            chargeExceptionStopFlag: 1,
            chargeExceptionStopProblem: '',
            errorRecordFlag: 1,
            errorRecordProblem: '',
            housingStateFlag: 1,
            housingStateProblem: '',
            threeProofingFlag: 1,
            threeProofingProblem: '',
            boxBaseFlag: 1,
            boxBaseProblem: '',
            cableCrossBoxFlag: 1,
            cableCrossBoxProblem: '',
            signIdentificationFlag: 1,
            signIdentificationProblem: '',
            equipmentOperationFlag: 1,
            equipmentOperationProblem: '',
            doorLockFlag: 1,
            doorLockProblem: '',
            internalWiringFlag: 1,
            internalWiringProblem: '',
          },
        },
        rules: {
          inspectPerson: [
            { required: true, message: '请输入电站名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('电站名称'),
            },
          ],
          inspectDate: [{ required: true, message: '请选择巡检时间', trigger: 'blur' }],
          stationName: [
            { required: true, message: '请输入电站名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('电站名称'),
            },
          ],
          stationAddress: [
            { required: true, message: '请输入电站地址', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.addressType.regexp,
              message: regexpObj.regexp.input.addressType.errorTips.error('电站地址'),
            },
          ],
          equipmentNum: [
            { required: true, message: '请输入设备数量', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                '设备数量',
                99999999
              ),
            },
          ],
          pileType: [{ required: true, message: '请选择桩类型', trigger: 'blur' }],
          moduleConfigNum: [
            { required: true, message: '请输入模块配置数量', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                '模块配置数量',
                99999999
              ),
            },
          ],
          moduleWorkNum: [
            { required: true, message: '请输入模块工作数量', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
              message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(
                '模块工作数量',
                99999999
              ),
            },
          ],
          totalElectricalValue: [
            // { required: true, message: '请输入总电度值', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
              message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(
                '总电度值',
                99999999
              ),
            },
          ],
          incomePowerSupplyVoltage: [
            // { required: true, message: '请输入AB', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
              message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error('AB', 99999999),
            },
          ],
          incomePowerSupplyVoltage1: [
            // { required: true, message: '请输入BC', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
              message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error('BC', 99999999),
            },
          ],
          incomePowerSupplyVoltage2: [
            // { required: true, message: '请输入AC', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
              message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error('AC', 99999999),
            },
          ],
          exchangeVoltage: [
            // { required: true, message: '请输入A相', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
              message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(
                'A相',
                99999999
              ),
            },
          ],
          exchangeVoltage1: [
            // { required: true, message: '请输入B相', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
              message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(
                'B相',
                99999999
              ),
            },
          ],
          exchangeVoltage2: [
            // { required: true, message: '请输入C相', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
              message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(
                'C相',
                99999999
              ),
            },
          ],
          softwareVersion: [
            // { required: true, message: '请输入软件版本模块', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.inlineTextType.regexp,
              message: regexpObj.regexp.input.inlineTextType.errorTips.error('软件版本模块'),
            },
          ],
          softwareVersion1: [
            // { required: true, message: '请输入软件版本监控', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.inlineTextType.regexp,
              message: regexpObj.regexp.input.inlineTextType.errorTips.error('软件版本监控'),
            },
          ],
          softwareVersion2: [
            // { required: true, message: '请输入软件版本控制板', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.inlineTextType.regexp,
              message: regexpObj.regexp.input.inlineTextType.errorTips.error('软件版本控制板'),
            },
          ],
          carModel: [
            // { required: true, message: '请输入车型厂家', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.inlineTextType.regexp,
              message: regexpObj.regexp.input.inlineTextType.errorTips.error('车型厂家'),
            },
          ],
          carModel1: [
            // { required: true, message: '请输入车型型号', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.inlineTextType.regexp,
              message: regexpObj.regexp.input.inlineTextType.errorTips.error('车型型号'),
            },
          ],
          parameterSetCheck: [
            // { required: true, message: '请输入参数设置检查', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
              message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(
                '参数设置检查',
                99999999
              ),
            },
          ],
          parameterSetCheck1: [
            // { required: true, message: '请输入参数设置检查', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
              message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(
                '监控限电流',
                99999999
              ),
            },
          ],
          parameterSetCheck2: [
            // { required: true, message: '请输入参数设置检查', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
              message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(
                '读卡器波特率/类型',
                99999999
              ),
            },
          ],
          electronicFiles: [
            // { required: true, message: '请输入电子档案', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.inlineTextType.regexp,
              message: regexpObj.regexp.input.inlineTextType.errorTips.error('车型型号'),
            },
          ],
        },
      };
    },
    computed: {
      formData() {
        const formData = {
          inspectPerson: this.ruleForm.inspectPerson,
          inspectDate: this.ruleForm.inspectDate,
          stationId: 0,
          stationName: this.ruleForm.stationName,
          stationAddress: this.ruleForm.stationAddress,
          equipmentNum: this.ruleForm.equipmentNum,
          pileType: this.ruleForm.pileType,
          moduleConfigNum: this.ruleForm.moduleConfigNum,
          moduleWorkNum: this.ruleForm.moduleWorkNum,
          totalElectricalValue: this.ruleForm.totalElectricalValue,
          incomePowerSupplyVoltage: `${this.ruleForm.incomePowerSupplyVoltage}-${this.ruleForm.incomePowerSupplyVoltage1}-${this.ruleForm.incomePowerSupplyVoltage2}`,

          exchangeVoltage: `${this.ruleForm.exchangeVoltage}-${this.ruleForm.exchangeVoltage1}-${this.ruleForm.exchangeVoltage2}`,

          softwareVersion: `${this.ruleForm.softwareVersion}-${this.ruleForm.softwareVersion1}-${this.ruleForm.softwareVersion2}`,

          carModel: `${this.ruleForm.carModel}-${this.ruleForm.carModel1}`,

          parameterSetCheck: `${this.ruleForm.parameterSetCheck}-${this.ruleForm.parameterSetCheck1}-${this.ruleForm.parameterSetCheck2}`,

          electronicFiles: this.ruleForm.electronicFiles,
          filePath: this.ruleForm.filePath,
          detail1: {
            chargeFireProtectionFlag: this.ruleForm.detail1.chargeFireProtectionFlag,
            chargeFireProtectionProblem: this.ruleForm.detail1.chargeFireProtectionProblem,
            pileSurfaceMarkingFlag: this.ruleForm.detail1.pileSurfaceMarkingFlag,
            pileSurfaceMarkingProblem: this.ruleForm.detail1.pileSurfaceMarkingProblem,
            pileBaseFlag: this.ruleForm.detail1.pileBaseFlag,
            pileBaseProblem: this.ruleForm.detail1.pileBaseProblem,
            chargeGunFlag: this.ruleForm.detail1.chargeGunFlag,
            chargeGunProblem: this.ruleForm.detail1.chargeGunProblem,
            protectLightningSystemFlag: this.ruleForm.detail1.protectLightningSystemFlag,
            protectLightningSystemProblem: this.ruleForm.detail1.protectLightningSystemProblem,
            distributionBoxLockFlag: this.ruleForm.detail1.distributionBoxLockFlag,
            distributionBoxLockProblem: this.ruleForm.detail1.distributionBoxLockProblem,
            distributionBoxComponentFlag: this.ruleForm.detail1.distributionBoxComponentFlag,
            distributionBoxComponentProblem: this.ruleForm.detail1.distributionBoxComponentProblem,
            boxInnerComponentFlag: this.ruleForm.detail1.boxInnerComponentFlag,
            boxInnerComponentProblem: this.ruleForm.detail1.boxInnerComponentProblem,
            incomeCableFlag: this.ruleForm.detail1.incomeCableFlag,
            incomeCableProblem: this.ruleForm.detail1.incomeCableProblem,
            dustProofNetFlag: this.ruleForm.detail1.dustProofNetFlag,
            dustProofNetProblem: this.ruleForm.detail1.dustProofNetProblem,
            fireproofMudFlag: this.ruleForm.detail1.fireproofMudFlag,
            fireproofMudProblem: this.ruleForm.detail1.fireproofMudProblem,
            pileInnerModuleFlag: this.ruleForm.detail1.pileInnerModuleFlag,
            pileInnerModuleProblem: this.ruleForm.detail1.pileInnerModuleProblem,
            circuitBoardConnectFlag: this.ruleForm.detail1.circuitBoardConnectFlag,
            circuitBoardConnectProblem: this.ruleForm.detail1.circuitBoardConnectProblem,
            moduleFanFlag: this.ruleForm.detail1.moduleFanFlag,
            moduleFanProblem: this.ruleForm.detail1.moduleFanProblem,
            checkPileVoltageFlag: this.ruleForm.detail1.checkPileVoltageFlag,
            checkPileVoltageProblem: this.ruleForm.detail1.checkPileVoltageProblem,
            checkCircuitBoardFlag: this.ruleForm.detail1.checkCircuitBoardFlag,
            checkCircuitBoardProblem: this.ruleForm.detail1.checkCircuitBoardProblem,
            emergencyStopButtonFlag: this.ruleForm.detail1.emergencyStopButtonFlag,
            emergencyStopButtonProblem: this.ruleForm.detail1.emergencyStopButtonProblem,
            displayScreenPageFlag: this.ruleForm.detail1.displayScreenPageFlag,
            displayScreenPageProblem: this.ruleForm.detail1.displayScreenPageProblem,
            cabinetFanFlag: this.ruleForm.detail1.cabinetFanFlag,
            cabinetFanProblem: this.ruleForm.detail1.cabinetFanProblem,
          },
          detail2: {
            useUserCardFlag: this.ruleForm.detail2.useUserCardFlag,
            useUserCardProblem: this.ruleForm.detail2.useUserCardProblem,
            pileDisplayScreenFlag: this.ruleForm.detail2.pileDisplayScreenFlag,
            pileDisplayScreenProblem: this.ruleForm.detail2.pileDisplayScreenProblem,
            checkScanCodeFlag: this.ruleForm.detail2.checkScanCodeFlag,
            checkScanCodeProblem: this.ruleForm.detail2.checkScanCodeProblem,
            checkGunInterfaceFlag: this.ruleForm.detail2.checkGunInterfaceFlag,
            checkGunInterfaceProblem: this.ruleForm.detail2.checkGunInterfaceProblem,
            screenChargeValueFlag: this.ruleForm.detail2.screenChargeValueFlag,
            screenChargeValueProblem: this.ruleForm.detail2.screenChargeValueProblem,
            chargeExceptionStopFlag: this.ruleForm.detail2.chargeExceptionStopFlag,
            chargeExceptionStopProblem: this.ruleForm.detail2.chargeExceptionStopProblem,
            errorRecordFlag: this.ruleForm.detail2.errorRecordFlag,
            errorRecordProblem: this.ruleForm.detail2.errorRecordProblem,
            housingStateFlag: this.ruleForm.detail2.housingStateFlag,
            housingStateProblem: this.ruleForm.detail2.housingStateProblem,
            threeProofingFlag: this.ruleForm.detail2.threeProofingFlag,
            threeProofingProblem: this.ruleForm.detail2.threeProofingProblem,
            boxBaseFlag: this.ruleForm.detail2.boxBaseFlag,
            boxBaseProblem: this.ruleForm.detail2.boxBaseProblem,
            cableCrossBoxFlag: this.ruleForm.detail2.cableCrossBoxFlag,
            cableCrossBoxProblem: this.ruleForm.detail2.cableCrossBoxProblem,
            signIdentificationFlag: this.ruleForm.detail2.signIdentificationFlag,
            signIdentificationProblem: this.ruleForm.detail2.signIdentificationProblem,
            equipmentOperationFlag: this.ruleForm.detail2.equipmentOperationFlag,
            equipmentOperationProblem: this.ruleForm.detail2.equipmentOperationProblem,
            doorLockFlag: this.ruleForm.detail2.doorLockFlag,
            doorLockProblem: this.ruleForm.detail2.doorLockProblem,
            internalWiringFlag: this.ruleForm.detail2.internalWiringFlag,
            internalWiringProblem: this.ruleForm.detail2.internalWiringProblem,
          },
        };
        return formData;
      },
    },
    methods: {
      /**
       * @desc  图片上传
       */
      uploadImg(file, fileList, isCheck, txt) {
        console.log('txt+++', txt);
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadImg(formData).then(res => {
            this.ruleForm.filePath.push(res);
          });
        }
      },
      /**
       * @desc 图片删除
       * */
      handleRemoveLogo(file) {
        console.log('file+++', file);
        console.log('this.ruleForm.stationImageList+++000', this.ruleForm.filePath);
        this.ruleForm.filePath.splice(
          this.ruleForm.filePath.findIndex(item => item.url === file.url),
          1
        );
        console.log('this.ruleForm.stationImageList+++111', this.ruleForm.filePath);
      },
      /**
       * @desc 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件
       * */
      submitForm() {
        return new Promise((resolve, reject) => {
          this.validateAllForm()
            .then(() => {
              omManagementApi
                .queryForPageAdd(this.formData)
                .then(res => {
                  console.log('res++++新增', res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            })
            .catch(err => {
              console.log(err);
              reject();
            });
        });
      },
      /**
       * @desc 校验当前表格是否通过
       * */
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs[`ruleForm${this.active}`].validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
      /**
       * @desc 校验全部表格是否通过
       * */
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(err => {
              console.log('err++++', err);
              reject();
            });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .pileInspection-add {
    .upload-img-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      > div {
        margin-right: 16px;
      }
    }
    .flex-box {
      ::v-deep .el-form-item__content {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
      }
    }

    .both-margin {
      margin: 0 20px;
    }

    .l-margin {
      margin-left: 20px;
    }

    .m-top {
      margin-top: 10px;
    }

    .el-steps {
      margin: 20px 0;
    }
    ::v-deep .el-step__head.is-success {
      color: rgb(52, 158, 250);
      border-color: rgb(52, 158, 250);
    }
    ::v-deep .el-step__title.is-success {
      font-weight: bold;
      color: rgb(52, 158, 250);
    }
    ::v-deep .el-step__description.is-success {
      color: rgb(52, 158, 250);
    }
  }
</style>
