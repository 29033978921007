<!--
 * @Author: wang peng
 * @Description: 充电桩巡检
-->
<template>
  <scroll-layout class="fleet-match-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <ykc-table
      ref="YkcTable"
      operateFixedType="right"
      :data="tableData"
      :title="tableTitle"
      :columns="tableColumns"
      :operateButtons="tableOperateButtons.filter(item => item.enabled())">
      <template #headerButtons>
        <div class="table-header-button">
          <ykc-button v-rbac="'maintain:personnel:pileInspection:add'" type="plain" @click="add">
            新增
          </ykc-button>
        </div>
      </template>
      <ykc-pagination
        align="right"
        slot="pagination"
        ref="YkcPagination"
        :total="pageInfo.total"
        :page-size.sync="pageInfo.size"
        :current-page.sync="pageInfo.current"
        @size-change="handlePaginationSizeChange"
        @current-change="handlePaginationCurrentChange"></ykc-pagination>
    </ykc-table>
    <ykc-drawer
      title="充电桩巡检表新增"
      :show.sync="showDrawer"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <AddOrEdit
          ref="addOrEdit"
          :id="id"
          :active="stepsIndex"
          v-if="drawerType === 'add' || drawerType === 'edit'"></AddOrEdit>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>
<script>
  import { code } from '@/utils';
  import { omManagementApi } from '@/service/apis';
  import YkcDialog from '@/components/ykc-ui/dialog';

  import AddOrEdit from './AddOrEdit.vue';

  export default {
    name: 'pileInspectionList',
    components: {
      AddOrEdit,
    },
    data() {
      return {
        drawerType: '',
        id: null,
        showDrawer: false,
        maxIndex: 2,
        stepsIndex: 0,
        searchParams: {
          stationName: '',
          pileType: '',
          inspectPerson: '',
          inspectDateStart: '',
          inspectDateEnd: '',
        },
        tableTitle: '巡检列表',
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { label: '电站名称', prop: 'stationName', minWidth: '200px' },
          {
            label: '电站地址',
            prop: 'stationAddress',
            title: false,
            scopedSlots: {
              defaultTitle: () => '',
              default: ({ row }) => {
                return (
                  <el-tooltip effect="dark" content={row.stationAddress} placement="top-start">
                    <span>{row.stationAddress}</span>
                  </el-tooltip>
                );
              },
            },
            minWidth: '200px',
          },
          { label: '设备数量（台）', prop: 'equipmentNum', minWidth: '200px' },
          {
            label: '巡检情况',
            prop: 'directTerminalNum',
            scopedSlots: {
              default: ({ row }) => {
                const tagOk = require('@/assets/images/tag-ok.png');
                const tagNO = require('@/assets/images/tag-no.png');
                return (
                  <div class="flex">
                    <div style="margin-right:4px">
                      <img class="icon icon-tag" src={tagOk} />
                      <span class="row-item-txt">{row.trueNum ?? '--'}</span>
                    </div>
                    <div>
                      <img class="icon icon-tag" src={tagNO} />
                      <span class="row-item-txt">{row.errorNum ?? '--'}</span>
                    </div>
                  </div>
                );
              },
            },
            minWidth: '200px',
          },
          {
            label: '桩类型',
            prop: 'startStatus',
            scopedSlots: {
              default: ({ row }) => {
                const startStatus = row.pileType === 1 ? 'status-blue' : 'status-red';
                return (
                  <span class={['userStatus', startStatus]}>
                    {row.pileType === 1 ? '直流' : '交流'}
                  </span>
                );
              },
            },
            minWidth: '200px',
          },
          { label: '巡检时间', prop: 'inspectDate', minWidth: '200px' },
          { label: '巡检人', prop: 'inspectPerson', minWidth: '200px' },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                showTitle: false,
                showFooter: true,
                dialogType: 'feedback',
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.stepsIndex = 0;
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '上一步',
            enabled: () => {
              return this.stepsIndex !== 0;
            },
            disabled: () => false,
            handler: () => {
              this.stepsIndex--;
            },
          },
          {
            label: '下一步',
            enabled: () => {
              return this.stepsIndex !== this.maxIndex;
            },
            disabled: () => false,
            handler: () => {
              this.$refs.addOrEdit.validateForm().then(() => {
                this.stepsIndex++;
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return this.stepsIndex === this.maxIndex;
            },
            handler: () => {
              this.$refs.addOrEdit
                .submitForm()
                .then(() => {
                  this.stepsIndex = 0;
                  this.showDrawer = false;
                  this.requestList();
                })
                .finally(() => {});
            },
          },
        ],
      };
    },
    computed: {
      searchData() {
        const all = { id: '', name: '全部' };
        return [
          {
            comName: 'YkcDatePicker',
            key: 'operationTime',
            label: '巡检时间 ',
            placeholder: '请选择巡检时间',
          },
          {
            comName: 'YkcDropdown',
            key: 'pileType',
            label: '桩类型',
            placeholder: '请选择桩类型',
            data: [
              all,
              ...[
                { id: '1', name: '直流' },
                { id: '2', name: '交流' },
              ],
            ],
          },
          {
            comName: 'YkcInput',
            key: 'stationName',
            label: '电站名称',
            placeholder: '请输入电站名称',
          },
          {
            comName: 'YkcInput',
            key: 'inspectPerson',
            label: '巡检人',
            placeholder: '请输入巡检人',
          },
        ];
      },
    },
    created() {
      this.requestList();
      this.initData();
    },
    methods: {
      /**
       * 请求列表数据
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          size: this.pageInfo.size,
          current: this.pageInfo.current,
        };
        omManagementApi
          .queryForPageList(reqParams)
          .then(res => {
            this.tableData = res.records;
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            id: '2',
            text: '详情',
            enabled: () => code('maintain:personnel:pileInspection:detail'),
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/operationManagement/scenePersonnel/pileInspection/Detail',
                query: { id: row.id },
              });
            },
          },
        ];
      },
      /**
       * 新增
       * */
      add() {
        this.stepsIndex = 0;
        this.drawerType = 'add';
        this.showDrawer = true;
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        Object.assign(this.searchParams, searchData);
        this.pageInfo.current = 1;

        if (searchData.operationTime && searchData.operationTime.length > 0) {
          [this.searchParams.inspectDateStart, this.searchParams.inspectDateEnd] =
            searchData.operationTime;
        }

        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
        this.searchParams.type = 1;
      },
      /**
       *
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       *
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      /**
       *
       * */
      onAddDrawerClose(done) {
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: dialogDone => {
            dialogDone();
          },
          onConfirm: dialogDone => {
            dialogDone();
            drawerDone();
          },
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-table {
    .el-table .el-table__row {
      .cell .userStatus {
        padding-left: 8px;
        position: relative;
      }

      .cell .userStatus::after {
        display: block;
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background: #d0021b;
        position: absolute;
        top: 5.5px;
        left: 0;
      }

      .cell .status-blue::after {
        background: #4d8cfd;
      }

      .cell .status-green::after {
        background: #02c498;
      }

      .cell .status-black::after {
        background: #9b9b9b;
      }
    }

    .sealBtn {
      color: #4d8cfd;
      cursor: pointer;
    }

    .row-item {
      padding: 0px 4px;
      font-size: 10px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      border-radius: 7px;
      display: inline-block;
    }

    .icon-tag {
      width: 20px;
      height: 20px;
      position: relative;
      top: 4px;
    }

    .row-item-txt {
      padding: 0 4px;
      min-width: 30px;
      text-align: center;
      display: inline-block;
    }

    .row-green {
      background: linear-gradient(180deg, #07c160 0%, #02e495 100%);
    }

    .row-blue {
      background: linear-gradient(180deg, #1779fe 0%, #72aeff 100%);
    }
  }
</style>
